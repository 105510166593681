import { FunctionComponent } from 'react'
import styled from 'styled-components'
import bandcampPNG from 'images/bandcamp.png'
import { colours, mixins } from 'styles'

const StyledBandcampLink = styled.div`
  background-color: ${colours.white};

  font-size: 1.6rem;
  .inner-wrapper {
    display: flex;
    justify-content: center;
    ${mixins.innerWrapperMixin};
    a {
      display: inline-block;
      margin: 0 auto;
    }
    img {
      width: 18rem;
      margin-top: 1rem;
    }
  }
`

export interface BandcampLinkProps {
  bandcampUrl: string
}

export const BandcampLink: FunctionComponent<BandcampLinkProps> = ({ bandcampUrl }) => (
  <StyledBandcampLink>
    <div className="inner-wrapper">
      <a href={bandcampUrl} target="_blank" rel="noreferrer nofollow noopener">
        <img src={bandcampPNG} alt="Bandcamp" />
      </a>
    </div>
  </StyledBandcampLink>
)
