import { FunctionComponent } from 'react'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import { PaginatorWrapper } from 'components/PaginatorWrapper'
import { Edges, ContentfulRelease, ContentfulPaginationData } from 'types'
import { useModalImage } from 'components/hooks/useModalImage'
import { Release } from 'components/Release'
import { BandcampLink } from 'components/BandcampLink'
import { removeEdges } from 'helpers'

export interface ReleaseWithId extends ContentfulRelease {
  contentful_id: string
}

export interface AllReleasesProps {
  data: {
    releases: Edges<ReleaseWithId>
    page: {
      config: {
        bandcampUrl: string
      }
    }
  }
  pageContext: ContentfulPaginationData
}

export const AllReleases: FunctionComponent<AllReleasesProps> = ({ data, pageContext }) => {
  const setModalImage = useModalImage()

  const ids = pageContext.group.map(({ node }) => node.contentful_id)

  const releases = removeEdges(data.releases).filter(({ contentful_id }) => ids.includes(contentful_id))

  return (
    <>
      <BandcampLink bandcampUrl={data.page.config.bandcampUrl} />
      <PaginatorWrapper
        pageContext={pageContext}
        children={() =>
          releases.map((release) => {
            const onImageClick = () => {
              const backCover = <Img alt={`${release.releaseId} back cover image`} fluid={release.backCover.fluid} />
              setModalImage(backCover)
            }

            const cover = <Img alt={`${release.releaseId} cover image`} fluid={release.cover.fluid} />
            return (
              <Release
                key={release.releaseId}
                artist={release.artist.name}
                slug={release.artist.slug}
                title={release.title}
                releaseId={release.releaseId}
                bandcampId={release.bandcampId}
                previewAddress={release.previewAddress}
                releaseDate={release.releaseDate}
                shortDescription={release.shortDescription}
                className="release-preview"
                image={cover}
                onImageClick={onImageClick}
              />
            )
          })
        }
      />
    </>
  )
}

export default AllReleases

export const query = graphql`
  query AllReleasesQuery {
    page: contentfulPage(name: { eq: "releases" }) {
      config: pageConfig {
        bandcampUrl
      }
    }
    releases: allContentfulRelease(filter: { isFuture: { eq: false } }, sort: { order: DESC, fields: [releaseDate] }) {
      edges {
        node {
          contentful_id
          title
          artist {
            name
            slug
          }
          releaseDate(formatString: "Do MMMM, YYYY")
          releaseId
          bandcampId
          previewAddress
          shortDescription {
            raw
          }
          cover {
            fluid(maxWidth: 790, sizes: "(max-width: 850px) 94vw, 320px") {
              ...GatsbyContentfulFluid
            }
          }
          backCover {
            fluid(maxWidth: 623) {
              ...GatsbyContentfulFluid
            }
          }
        }
      }
    }
  }
`
