import { FunctionComponent, ReactNode } from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import { colours, mixins, constants } from 'styles'
import { ImageButton, ReleaseLink } from 'components/ReleaseIcons'
import { getBandCampEmbedAddress, getComponentFromRT, RichTextType } from 'helpers'
import { useGlobalContext } from 'components/store/globalContext'

const ReleaseWrapper = styled.div`
  ${mixins.textSection};
  display: grid;
  grid-template-columns: [bandcamp-start] 280px [bandcamp-end] 1fr;
  grid-template-rows: auto [bandcamp-start] auto [bandcamp-end];
  grid-column-gap: 20px;

  @media screen and (max-width: 720px) {
    grid-template-columns: 1fr;
    grid-template-rows: auto;
  }
  margin-bottom: 2rem;
  border: 1px solid ${colours.orange};
  background-color: ${colours.white};

  .details {
    flex-grow: 1;
    padding: 1rem 1rem 1rem 0;

    @media screen and (max-width: 720px) {
      padding-left: 2rem;
      padding-right: 2rem;
    }
    @media ${constants.breakpoints.mobile} {
      padding-left: 1.5rem;
      padding-right: 1.5rem;
    }

    .title {
      display: inline-block;
      min-width: 100px;
      color: ${colours.greyOne};
      font-size: 2rem;
      font-weight: 600;
    }

    .name {
      color: ${colours.greyOne};
      font-size: 1.8rem;
      font-weight: 300;
    }

    .release-date {
      margin-top: 1.2rem;
      margin-bottom: 1rem;
      font-style: italic;
      font-size: 1.6rem;
    }

    hr {
      background: ${colours.greyThree};
      border: 0 none;
      height: 1px;
      margin: 0.6rem 0 0 0;
      width: 4.2rem;
    }

    .release-excerpt {
      margin-bottom: 0;
      @media screen and (max-width: 720px) {
        margin-bottom: 0;
      }
      @media ${constants.breakpoints.mobile} {
        font-size: 1.6rem;
      }
    }
  }

  .release-link {
    grid-column-start: bandcamp-end;
    padding-bottom: 1rem;
    @media screen and (max-width: 720px) {
      grid-column-start: unset;
      margin-top: 0;
      padding-left: 2rem;
      padding-right: 2rem;
      padding-bottom: 2rem;
    }
    @media ${constants.breakpoints.mobile} {
      padding-left: 1.5rem;
      padding-right: 1.5rem;
    }
  }

  .cover {
    position: relative;
    flex-basis: 300px;
    min-width: 250px;
  }

  .cover-buttons {
    background-color: rgba(255, 255, 255, 0.5);
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    transition: all 400ms ease 0s;
  }

  :hover .cover-buttons {
    opacity: 1;
  }

  .bandcamp {
    grid-column-start: bandcamp-start;
    grid-column-end: bandcamp-end;
    grid-row-start: bandcamp-start;
    grid-row-end: bandcamp-end;
    border: 0;
    width: 100%;
    height: 42px;
    @media screen and (max-width: 720px) {
      grid-column-start: auto;
      grid-column-end: auto;
      grid-row-start: auto;
      grid-row-end: auto;
    }
  }
`

export interface ReleaseProps {
  artist: string
  slug: string
  title: string
  releaseId: string
  bandcampId: string
  previewAddress: string
  releaseDate: Date
  shortDescription: RichTextType
  image: ReactNode
  onImageClick: () => void
  children?: never
  className?: string
}

export const Release: FunctionComponent<ReleaseProps> = ({
  image,
  artist,
  title,
  shortDescription,
  releaseId,
  releaseDate,
  slug,
  bandcampId,
  previewAddress,
  onImageClick,
  className,
}) => {
  const [{ cookiesAccepted }] = useGlobalContext()
  return (
    <ReleaseWrapper className={className}>
      <div className="cover">
        {image}
        <div className="cover-buttons">
          <ImageButton onClick={onImageClick}>
            <span className="visually-hidden">Show Cover Image</span>
          </ImageButton>
          <ReleaseLink to={`/artists/${slug}/#${releaseId}`}>
            <span className="visually-hidden">Go To Release Page</span>
          </ReleaseLink>
        </div>
      </div>
      {cookiesAccepted && (
        <iframe className="bandcamp" seamless src={getBandCampEmbedAddress(bandcampId!)}>
          <a href={previewAddress} target="_blank" rel="noreferrer nofollow noopener">
            {title} by
            {artist}
          </a>
        </iframe>
      )}
      <div className="details">
        <h4 className="title">{title}</h4>
        <p className="name">
          {artist},{releaseId}
        </p>
        <hr />
        <div className="release-date">{releaseDate}</div>
        {getComponentFromRT(shortDescription, 'release-excerpt')}
      </div>
      <div className="release-link">
        <Link to={`/artists/${slug}/#${releaseId}`}>Read more</Link>
      </div>
    </ReleaseWrapper>
  )
}
